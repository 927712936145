body {
    font-family: 'Montserrat', sans-serif;
}
.zx{
    color: yellow;
}
.header-nightsky {
    color: white;
    background: url("../images/hero.jpg") no-repeat;
    background-size: cover;
    background-position: bottom;
    padding-bottom: 100px;
}

.header-nightsky .navbar-nav>li>a {
    color: white;
    font-size: 18px;
    border-radius: 10px;
}

.header-nightsky .navbar {
    margin-bottom: 0px;
    padding-top: 20px;
    padding-bottom: 20px;
    width: 100%;
    border-bottom:none;
    background-color: transparent;
    min-width: 300px;
    border: none;
}

.header-nightsky .navbar-default .navbar-nav>.open>a,
.header-nightsky .navbar-default .navbar-nav>.open>a:focus,
.header-nightsky .navbar-default .navbar-nav>.open>a:hover {
    color: #ccc;
    background-color: transparent;
}

.header-nightsky .nav>li>a:focus,
.header-nightsky .nav>li>a:hover {
    color: #ccc;
    background-color: transparent;
}

.header-nightsky .navbar-nav>li {
    margin-right: 20px;
}

.header-nightsky .navbar-nav {
   margin-top: 12px;
}

.header-nightsky .navbar-toggle {
    background-color: transparent !important;
    margin-top: 20px;
    border: 1px solid #fff;
}

.header-nightsky .navbar-toggle .icon-bar {
    background-color: white;
}

.header-nightsky .navbar-brand {
    color: white;
    font-size: 50px;
    margin-top: 10px;
    margin-bottom: 10px;
}

.header-nightsky .navbar-brand:hover {
    color: #ccc;
}

.header-nightsky .hero {
    text-align: center;
    margin-top: 80px;
    margin-bottom: 100px;
}

.header-nightsky .hero h1 {
    color: white;
    font-weight: bold;
    font-size: 60px;
    margin-bottom: 36px;
}


.header-nightsky .hero p {
    color: rgba(255, 255, 255, 0.6);
    font-size: 22px;
    max-width: 660px;
    margin: 0 auto 20px;
    font-weight: normal;
    line-height: 1.5;
}

.header-nightsky .btn-primary {
    color: #fff;
    background-color: transparent;
    border-color: #fffbfb !important;
    outline:none;
    margin-right: 20px;
    margin-top: 20px;
    font-size: 24px;
    padding: 18px 24px;
    transition:0.2s background-color;
}

.header-nightsky .btn-primary:hover {
    background-color:rgba(255,255,255,0.1);
}

.header-nightsky .btn-primary:active {
    transform:translateY(1px);
}

