.link-css:hover{
	transform: translateY(-5px); /* Lift the card slightly on hover */
	box-shadow: 0 10px 20px rgba(255, 255, 255, 0.2);}
.link-css{
	transition: transform 0.3s ease;
	box-shadow: 0 4px 8px rgba(255, 255, 255, 0.1); /* Light box-shadow */
}
.product {
    width: 100%;
	padding-bottom: 60px;
	padding-top: 80px;
}

.product-page {
	padding-top: 60px;
}

.btn__all {
	text-align: right;
	margin-bottom: 30px;
}

.trending__product {
	margin-bottom: 50px;
}
.mafki{
    height: 487px;
	padding: 0 15px;
}
.popular__product {
	margin-bottom: 50px;
}

.recent__product {
	margin-bottom: 50px;
}

.product__item {
	margin-bottom: 30px;
}

.product__item__pic {
	height: 325px;
	position: relative;
	border-radius: 15px;
}
.background-image{
 border-radius: 5px;
 width: 100%;
}
.background-image2{
    width: 100%;
    height:100%;
    object-fit: cover;
    border-radius: 15px;
}
.product__item__pic .ep {
	font-size: 13px;
	color: #ffffff;
	background: #fe4604;
	display: inline-block;
	padding: 2px 12px;
	border-radius: 4px;
	position: absolute;
	left: 10px;
	top: 10px;
}

.product__item__pic .comment {
	font-size: 13px;
	color: #ffffff;
	background: #3d3d3d;
	display: inline-block;
	padding: 2px 10px;
	border-radius: 4px;
	position: absolute;
	left: 10px;
	bottom: 10px;
}

.set-bg {
	background-repeat: no-repeat;
	background-size: cover;
	background-position: top center;
}
.product__item__pic .view {
	font-size: 13px;
	color: #ffffff;
	background: #3d3d3d;
	display: inline-block;
	padding: 2px 10px;
	border-radius: 4px;
	position: absolute;
	right: 10px;
	bottom: 10px;
}

.product__item__text {
	padding-top: 20px;
    
}

.product__item__text ul {
	margin-bottom: 10px;
    
}

.product__item__text ul li {
	list-style: none;
	font-size: 10px;
	color: #ffffff;
	font-weight: normal;
padding: 1px 10px;
	background: rgba(255, 255, 255, 0.2);
	border-radius: 50px;
	display: inline-block;
}

.product__item__text h5 a {
	color: #ffffff;
	font-weight: normal;
	line-height: 26px;
    
}

.product__sidebar .section-title h5 {
	color: #ffffff;
	font-weight: normal;
	font-family: "Oswald", sans-serif;
	line-height: 21px;
	text-transform: uppercase;
	padding-left: 20px;
	position: relative;
}

.product__sidebar .section-title h5:after {
	position: absolute;
	left: 0;
	top: -6px;
	height: 32px;
	width: 4px;
	background: yellow;
	content: "";
}

.product__sidebar__view {
	position: relative;
	margin-bottom: 80px;
}

.product__sidebar__view .filter__controls {
	position: absolute;
	right: 0;
	top: -5px;
}

.product__sidebar__view .filter__controls li {
	list-style: none;
	font-size: 13px;
	color: #b7b7b7;
	display: inline-block;
	margin-right: 7px;
	cursor: pointer;
}

.product__sidebar__view .filter__controls li.active {
	color: #ffffff;
}

.product__sidebar__view .filter__controls li:last-child {
	margin-right: 0;
}

.product__sidebar__view__item {
	height: 190px;
	position: relative;
	border-radius: 5px;
	margin-bottom: 20px;
}

.product__sidebar__view__item .ep {
	font-size: 13px;
	color: #ffffff;
	background: #fe4604;
	display: inline-block;
	padding: 2px 12px;
	border-radius: 4px;
	position: absolute;
	left: 10px;
	top: 10px;
}

.product__sidebar__view__item .view {
	font-size: 13px;
	color: #ffffff;
	background: #3d3d3d;
	display: inline-block;
	padding: 2px 10px;
	border-radius: 4px;
	position: absolute;
	right: 10px;
	top: 10px;
}

.product__sidebar__view__item h5 {
	position: absolute;
	left: 0;
	bottom: 25px;
	width: 100%;
	padding: 0 30px 0 20px;
}

.product__sidebar__view__item h5 a {
	color: #ffffff;
	font-weight: normal;
line-height: 26px;
}

.product__sidebar__comment {
	margin-bottom: 35px;
}

.product__sidebar__comment__item {
	margin-bottom: 20px;
	overflow: hidden;
}

.product__sidebar__comment__item__pic {
	float: left;
	margin-right: 15px;
}

.product__sidebar__comment__item__text {
	overflow: hidden;
}

.product__sidebar__comment__item__text ul {
	margin-bottom: 10px;
}

.product__sidebar__comment__item__text ul li {
	list-style: none;
	font-size: 10px;
	color: #ffffff;
	font-weight: normal;
padding: 1px 10px;
	background: rgba(255, 255, 255, 0.2);
	border-radius: 50px;
	display: inline-block;
}

.product__sidebar__comment__item__text h5 {
	margin-bottom: 10px;
}

.product__sidebar__comment__item__text h5 a {
	color: #ffffff;
	font-weight: normal;
line-height: 26px;
}

.product__sidebar__comment__item__text span {
	display: block;
	font-size: 13px;
	color: #b7b7b7;
}

.product__page__title {
	border-bottom: 2px solid rgba(255, 255, 255, 0.2);
	padding-bottom: 10px;
	margin-bottom: 30px;
}

.product__page__title .section-title {
	margin-bottom: 0;
}

.product__page__title .product__page__filter {
	text-align: right;
}

.product__page__title .product__page__filter p {
	color: #ffffff;
	display: inline-block;
	margin-bottom: 0;
	margin-right: 16px;
}

.product__page__title .product__page__filter .nice-select {
	float: none;
	display: inline-block;
	font-size: 15px;
	color: #3d3d3d;
	font-weight: normal;
border-radius: 0;
	padding-left: 15px;
	padding-right: 40px;
	height: 32px;
	line-height: 32px;
}

.product__page__title .product__page__filter .nice-select:after {
	border-bottom: 2px solid #111;
	border-right: 2px solid #111;
	height: 8px;
	top: 47%;
	width: 8px;
	right: 15px;
}

.product__page__title .product__page__filter .nice-select .list {
	margin-top: 0;
	border-radius: 0;
}

.product__pagination {
	padding-top: 15px;
}

.product__pagination a {
	display: inline-block;
	font-size: 15px;
	color: #b7b7b7;
	font-weight: normal;
height: 50px;
	width: 50px;
	border: 1px solid transparent;
	border-radius: 50%;
	line-height: 48px;
	text-align: center;
	-webkit-transition: all, 0.3s;
	-o-transition: all, 0.3s;
	transition: all, 0.3s;
}

.product__pagination a:hover {
	color: #ffffff;
}

.product__pagination a.current-page {
	border: 1px solid #ffffff;
}

.product__pagination a i {
	color: #b7b7b7;
	font-size: 15px;
}
.adss{
	padding-top: 10%;
}